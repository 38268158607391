<template>
  <div class="w-full h-full">
      <img src="../../assets/images/map-img.webp" alt="worldMap" class="object-cover h-full w-full">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>